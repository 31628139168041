import { get } from 'lodash'
import {
  DepartmentLocationGroup,
  KitGroupQuantity,
  LocationQuantity,
} from './DistributionResponse.model'
import { MessagingValue } from './MessagingResponse.model'
import { SignNotes } from './SignNotes.model'
import moment from 'moment'

export default class TableEditorFields {
  description?: string
  sign_type?: string
  sign_creator?: string
  non_retail_item_info?: TableEditorNonRetailItemInfo
  sign_template_info?: TableEditorSignTemplateInfo
  sign_size?: string
  sign_definition?: string
  department?: TableEditorDeptInfo
  unit_of_measure?: string
  set_quantity?: number
  separator?: string
  photography_needed?: boolean
  third_party_shipping?: boolean
  priority?: string
  endcap_type?: string
  program_name?: string
  ism_strategy?: string
  pog_categories?: any[]
  tie_to_product_dpci?: string
  tie_to_hardware_dpci?: string
  placement_on_shelf?: string
  quantity_per_placement?: string
  pog_unit_of_measure?: string
  pog_exclusions?: string
  printing?: TableEditorPrintingInfo
  distribution?: TableEditorDistributionInfo
  pricing?: TableEditorPricingInfo
  messaging?: TableEditorMessagingInfo
  sign_notes?: SignNotes
  group?: string
  on_pog_sign?: boolean
  spt_sign_on_pog?: boolean
  sign_status?: string

  constructor(props: any = {}) {
    this.description = props.description
    this.sign_type = props.sign_type
    this.sign_creator = props.sign_creator
    this.non_retail_item_info =
      props.non_retail_item_info &&
      new TableEditorNonRetailItemInfo(props.non_retail_item_info)
    this.sign_template_info =
      props.sign_template_info &&
      new TableEditorSignTemplateInfo(props.sign_template_info)
    this.sign_size = props.sign_size
    this.sign_definition = props.sign_definition
    this.department =
      props.department && new TableEditorDeptInfo(props.department)
    this.unit_of_measure = props.unit_of_measure
    this.set_quantity = props.set_quantity && parseInt(props.set_quantity)
    this.separator = props.separator
    this.photography_needed = props.photography_needed
    this.priority = props.priority
    this.endcap_type = props.endcap_type
    this.program_name = props.program_name
    this.ism_strategy = props.ism_strategy
    this.pog_categories = props.pog_categories
    this.tie_to_product_dpci = props.tie_to_product_dpci
    this.tie_to_hardware_dpci = props.tie_to_hardware_dpci
    this.placement_on_shelf = props.placement_on_shelf
    this.quantity_per_placement = props.quantity_per_placement
    this.pog_unit_of_measure = props.pog_unit_of_measure
    this.pog_exclusions = props.pog_exclusions
    this.printing =
      props.printing && new TableEditorPrintingInfo(props.printing)
    this.distribution =
      props.distribution && new TableEditorDistributionInfo(props.distribution)
    this.pricing = props.pricing && new TableEditorPricingInfo(props.pricing)
    this.messaging =
      props.messaging && new TableEditorMessagingInfo(props.messaging)
    this.sign_notes = props.sign_notes && new SignNotes(props.sign_notes)
    this.group = props.group
    this.on_pog_sign = props.on_pog_sign
    this.third_party_shipping = props.third_party_shipping
    this.spt_sign_on_pog = props.spt_sign_on_pog
    this.sign_status = props.sign_status
  }
}

export class TableEditorNonRetailItemInfo {
  item_dimensions?: TableEditorDimensions
  nominal_dimensions?: TableEditorDimensions
  product_vendor?: string
  end_date?: string
  low_water_mark?: number
  max_order_quantity?: number
  restricted: boolean
  restriction_description?: string

  constructor(props: any = {}) {
    this.item_dimensions =
      props.item_dimensions && new TableEditorDimensions(props.item_dimensions)
    this.nominal_dimensions =
      props.nominal_dimensions &&
      new TableEditorDimensions(props.nominal_dimensions)
    this.product_vendor = props.product_vendor
    this.end_date =
      props.end_date && moment(props.end_date).format('YYYY-MM-DD')
    this.low_water_mark = props.low_water_mark
    this.max_order_quantity = props.max_order_quantity
    this.restricted = props.restricted
    this.restriction_description = props.restriction_description
  }
}

export class TableEditorSignTemplateInfo {
  template_id: string
  source_id: string
  template_name: string
  size_editable: boolean
  standard_sign: boolean

  constructor(props: any = {}) {
    this.template_id = props.template_id
    this.template_name = props.template_name
    this.size_editable = props.size_editable
    this.standard_sign = props.standard_sign
    this.source_id = props.source_id
  }
}

export class TableEditorDimensions {
  depth?: string
  dimension_unit_of_measure?: string
  height?: string
  width?: string

  constructor(props: any = {}) {
    this.depth = props.depth && props.depth.toString()
    this.dimension_unit_of_measure =
      props.dimension_unit_of_measure &&
      props.dimension_unit_of_measure.toString()
    this.height = props.height && props.height.toString()
    this.width = props.width && props.width.toString()
  }
}

export class TableEditorDeptInfo {
  department_id: number
  department_name: string
  department_type_code: string
  division_id: number
  division_name: string
  group_id: number
  group_name: string
  sub_group_id: number
  sub_group_name: string

  constructor(props: any = {}) {
    this.department_id = props.department_id && parseInt(props.department_id)
    this.department_name = props.department_name
    this.department_type_code = props.department_type_code
    this.division_id = props.division_id && parseInt(props.division_id)
    this.division_name = props.division_name
    this.group_id = props.group_id && parseInt(props.group_id)
    this.group_name = props.group_name
    this.sub_group_id = props.sub_group_id && parseInt(props.sub_group_id)
    this.sub_group_name = props.sub_group_name
  }
}

export class TableEditorPrintingInfo {
  print_vendor?: string
  print_vendor_id?: string
  planned_sides?: number
  number_of_colors?: number
  substrate1?: string
  substrate2?: string
  finishing?: string
  coating?: string
  sides_print?: boolean
  pre_assembled?: boolean
  file_approved?: boolean
  dieline_name: string

  constructor(props: any = {}) {
    this.print_vendor = props.print_vendor
    this.print_vendor_id = props.print_vendor_id
    this.planned_sides = props.planned_sides && parseInt(props.planned_sides)
    this.number_of_colors =
      props.number_of_colors && parseInt(props.number_of_colors)
    this.substrate1 = props.substrate1
    this.substrate2 = props.substrate2
    this.finishing = props.finishing
    this.coating = props.coating
    this.sides_print = props.sides_print
    this.pre_assembled = props.pre_assembled
    this.file_approved = props.file_approved
    this.dieline_name = props.dieline_name
  }
}

export class TableEditorDistributionInfo {
  back_stock_quantity?: number
  include_msc_pog_locations?: boolean
  kit_by_itself?: boolean
  kit_group_quantities?: KitGroupQuantity[]
  kit_groups?: string[]
  kitting_vendor?: string
  kitting_vendor_id?: string
  location_filter_info?: TableEditorLocationFilterCriteria
  locations?: LocationQuantity[]
  locations_count?: number
  max_quantity_per_kit?: number
  msc_pog_locations?: number[]
  nrsc_quantity?: number
  quantity_per_store?: number
  total_quantity?: number
  include_new_stores: boolean

  constructor(props: any = {}) {
    this.back_stock_quantity =
      props.back_stock_quantity !== undefined &&
      props.back_stock_quantity !== ''
        ? parseInt(props.back_stock_quantity)
        : undefined
    this.include_msc_pog_locations = props.include_msc_pog_locations
    this.kit_by_itself = props.kit_by_itself
    this.kit_group_quantities =
      props.kit_group_quantities &&
      props.kit_group_quantities.length > 0 &&
      get(props, 'kit_group_quantities', [{}]).map(
        (item: any) => new KitGroupQuantity(item),
      )
    this.kit_groups = props.kit_groups
    this.kitting_vendor = props.kitting_vendor
    this.kitting_vendor_id = props.kitting_vendor_id
    this.location_filter_info =
      props.location_filter_info &&
      new TableEditorLocationFilterCriteria(props.location_filter_info)
    this.locations = props.locations
    this.locations_count = props.locations_count
    this.max_quantity_per_kit =
      props.max_quantity_per_kit && parseInt(props.max_quantity_per_kit)
    this.msc_pog_locations = props.msc_pog_locations
    this.nrsc_quantity =
      props.nrsc_quantity && props.nrsc_quantity !== ''
        ? parseInt(props.nrsc_quantity)
        : undefined
    this.quantity_per_store =
      props.quantity_per_store && props.quantity_per_store !== ''
        ? parseInt(props.quantity_per_store)
        : undefined
    this.total_quantity =
      props.total_quantity && props.total_quantity !== ''
        ? parseInt(props.total_quantity)
        : undefined
    this.include_new_stores = props.include_new_stores
  }
}

export class TableEditorLocationFilterCriteria {
  exclude_location_info?: TableEditorLocationInfo
  include_location_info?: TableEditorLocationInfo

  constructor(props: any = {}) {
    this.exclude_location_info =
      props.exclude_location_info &&
      new TableEditorLocationInfo(props.exclude_location_info)
    this.include_location_info =
      props.include_location_info &&
      new TableEditorLocationInfo(props.include_location_info)
  }
}

export class TableEditorLocationInfo {
  all_locations: boolean
  department_location_groups?: DepartmentLocationGroup
  locations?: string[]

  constructor(props: any = {}) {
    this.all_locations = props.all_locations || false
    this.department_location_groups =
      props.department_location_groups &&
      props.department_location_groups.length > 0
        ? get(props, 'department_locations_groups', [{}]).map(
            (item: any) => new DepartmentLocationGroup(item),
          )
        : undefined
    this.locations = props.locations
  }
}

export class TableEditorDeptLocationGroup {
  department_id: string
  group_id: string
  sub_group_ids: string[]
  quantity: number

  constructor(props: any = {}) {
    this.department_id = props.department_id || ''
    this.group_id = props.group_id || ''
    this.sub_group_ids = props.sub_group_ids || []
    this.quantity = props.quantity || 0
  }
}

export class TableEditorPricingInfo {
  estimated_quantity?: number
  estimated_unit_price?: number
  final_unit_price?: number
  po_number: string

  constructor(props: any = {}) {
    this.estimated_quantity =
      props.estimated_quantity && props.estimated_quantity !== ''
        ? parseInt(props.estimated_quantity)
        : undefined
    this.estimated_unit_price =
      !isNaN(props.estimated_unit_price) && props.estimated_unit_price !== ''
        ? parseFloat(props.estimated_unit_price)
        : undefined
    this.final_unit_price =
      !isNaN(props.final_unit_price) && props.final_unit_price !== ''
        ? parseFloat(props.final_unit_price)
        : undefined
    this.po_number = props.po_number
  }
}

export class TableEditorMessagingInfo {
  sign_size?: string
  item_info?: TableEditorMessagingItemInfo[]
  headline?: string
  mpp?: boolean
  topper_brand?: string
  disclaimer?: string

  constructor(props: any = {}) {
    this.sign_size = props.sign_size
    this.item_info =
      props.item_info?.length > 0
        ? get(props, 'item_info').map(
            (item: any) => new TableEditorMessagingItemInfo(item),
          )
        : undefined
    this.headline = props.headline
    this.mpp = props.mpp
    this.topper_brand = props.topper_brand
    this.disclaimer = props.disclaimer
  }
}

export class TableEditorMessagingItemInfo {
  messaging_type?: string
  messaging_value?: MessagingValue
  product_dpci: string
  attributes?: string[]
  product_description?: string
  product_tcin?: string

  constructor(props: any = {}) {
    this.messaging_type = props.messaging_type
    this.messaging_value =
      props.messaging_value && new MessagingValue(props.messaging_value)
    this.product_dpci = props.product_dpci || ''
    this.attributes = props.attributes
    this.product_description = props.product_description
    this.product_tcin = props.product_tcin
  }
}
