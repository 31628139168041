import { ValueGetterParams } from 'ag-grid-community'
import { get } from 'lodash'
import moment from 'moment'
import { UserType } from '../../App/constants/appConstants'
import {
  AutocompleteCellEditor,
  DateCellEditor,
  DepartmentCellEditor,
  NumberCellEditor,
  SelectCellEditor,
  TextCellEditor,
  ToggleCellEditor,
} from '../components/SignLibraryTable/editorComponents'
import AutocompletesubstrateCellEditor from '../components/SignLibraryTable/editorComponents/AutocompletesubstrateCellEditor'
import {
  Department,
  DPCI,
  FinalizedChip,
  ProjectName,
  BlueprintName,
  SetQuantity,
  StatusChip,
  TableDropdownMenu,
  TableToggle,
} from '../components/SignLibraryTable/frameworkComponents'
import { SIGN_ITEM_STATUS } from 'components/SignDetails/constants/signDetailsConstants'

export const dateValueGetter = (params: ValueGetterParams) =>
  get(params.data, params.column.getColId())
    ? moment(get(params.data, params.column.getColId())).format('MM/DD/YYYY')
    : ''

export const detailDateFilterParams = {
  browserDatePicker: true,
  filterOptions: ['inRange'],
  suppressAndOrCondition: true,
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (cellValue === null) {
      return -1
    }
    const cellDate = new Date(cellValue)
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
}

export const detailDateComparator = (value1: string, value2: string) => {
  const _dateToNum = (date: string) => {
    if (date === undefined || date === null || date.length !== 10) {
      return null
    }
    return (
      parseInt(date.substring(6, 10)) * 10000 +
      parseInt(date.substring(0, 2)) * 100 +
      parseInt(date.substring(3, 5))
    )
  }
  const date1 = _dateToNum(value1)
  const date2 = _dateToNum(value2)
  return date1 === null && date2 === null
    ? 0
    : date1 === null
    ? -1
    : date2 === null
    ? 1
    : date1 - date2
}

export const isEditable = (
  userInfo: any,
  signItemStatus: SIGN_ITEM_STATUS,
  colId: string,
  restricted: boolean,
  signTemplateTableName: string = '',
  sizeEditable: boolean = false,
) => {
  const isEndDate: boolean = colId === 'non_retail_item_info.end_date'
  const isFinishedDimension: boolean =
    colId === 'non_retail_item_info.item_dimensions.width' ||
    colId === 'non_retail_item_info.item_dimensions.height' ||
    colId === 'non_retail_item_info.item_dimensions.depth'
  const isFlatDimension: boolean =
    colId === 'non_retail_item_info.nominal_dimensions.width' ||
    colId === 'non_retail_item_info.nominal_dimensions.height' ||
    colId === 'non_retail_item_info.nominal_dimensions.depth'
  const isContractField: boolean =
    colId === 'non_retail_item_info.printing_info.dieline_name' ||
    colId === 'non_retail_item_info.printing_info.finishing' ||
    colId === 'non_retail_item_info.printing_info.planned_sides' ||
    colId === 'non_retail_item_info.printing_info.substrate1' ||
    colId === 'non_retail_item_info.printing_info.substrate2'

  if (
    (isFinishedDimension && signTemplateTableName !== 'CUSTOM') ||
    (isFlatDimension && !sizeEditable && signTemplateTableName !== 'CUSTOM') ||
    (isContractField && signTemplateTableName !== 'CUSTOM')
  ) {
    return false
  }

  const userCanEdit =
    userInfo.userType === UserType.INTERNAL &&
    userInfo.userPermissions.isSignAdmin

  const userIsIPP =
    userInfo.userType === UserType.INTERNAL &&
    userInfo.userPermissions.isKitEditor

  const userIsIppRequestable =
    userInfo.userType === UserType.INTERNAL &&
    (userInfo.userPermissions.isKitAdmin ||
      userInfo.userPermissions.isKitEditor ||
      userInfo.userPermissions.isSignEditor)

  if (
    colId === 'non_retail_item_info.max_order_quantity' ||
    colId === 'non_retail_item_info.low_water_mark' ||
    colId === 'non_retail_item_info.restricted'
  ) {
    return signItemStatus === SIGN_ITEM_STATUS.ACTIVE
      ? userCanEdit || userInfo.userPermissions.isSuperAdmin || userIsIPP
      : false
  }

  if (colId === 'non_retail_item_info.department') {
    return signItemStatus === SIGN_ITEM_STATUS.ACTIVE
      ? userCanEdit ||
          userInfo.userPermissions.isSuperAdmin ||
          userIsIppRequestable
      : false
  }

  if (colId === 'non_retail_item_info.restriction_description') {
    return restricted
  }

  return isEndDate
    ? userCanEdit || userIsIPP
    : signItemStatus === SIGN_ITEM_STATUS.ACTIVE
    ? userCanEdit
    : false
}

export const signLibraryGridComponents = {
  TableDropdownMenu: TableDropdownMenu,
  Dpci: DPCI,
  Department: Department,
  SignStatus: StatusChip,
  ProjectName: ProjectName,
  BlueprintName: BlueprintName,
  FinalizedCell: FinalizedChip,
  ToggleCell: TableToggle,
  SetQuantity: SetQuantity,
  /* === Cell Editor Components === */
  TextEditor: TextCellEditor,
  SelectEditor: SelectCellEditor,
  DepartmentEditor: DepartmentCellEditor,
  DateEditor: DateCellEditor,
  NumberEditor: NumberCellEditor,
  ToggleEditor: ToggleCellEditor,
  AutocompleteEditor: AutocompleteCellEditor,
  AutocompletesubstrateCellEditor: AutocompletesubstrateCellEditor,
}
